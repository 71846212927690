<template>
  <div class="cartera">
    <!-- CESTA -->
    <!-- <a-col :xs="24">
      <a-checkbox class="m-4 compare-checkbox" v-model:checked="plot">{{
        t("funds_printer.plot_values")
      }}</a-checkbox>
    </a-col> -->
    <a-space class="w-100 justify-content-between table-btn-group">
      <a-space>
        <a-tooltip :title="t('funds_printer.select_all_switch_tooltip')">
          <a-switch class="mx-3 mb-1" v-model:checked="selectAll" />
        </a-tooltip>
        <template v-if="enableActions">
          <a-tooltip :title="t('funds_printer.delete_selection_tooltip')">
            <label
              v-if="showDelete && !authStore.restrictions.includes(Restrictions['RemoveSelection'])"
              class="label-button"
              @click="clearSelectedBasket()"
            >
              {{
                t("funds_printer.delete_selection")
              }}
            </label>
          </a-tooltip>
        </template>
        <template v-else
          ><a-popconfirm
            :title="t('funds_printer.delete_all_confirm')"
            :ok-text="$t('search_funds.select_family_columns.yes')"
            placement="left"
            :cancel-text="$t('search_funds.select_family_columns.no')"
            @confirm="clearBasket"
          >
          <a-tooltip :title="t('funds_printer.delete_all_tooltip')">
              <label
                v-if="showDelete && !authStore.restrictions.includes(Restrictions['RemoveSelection'])"
                class="label-button"
              >{{
                  t("funds_printer.delete_all")
                }}
              </label>
            </a-tooltip>
          </a-popconfirm>
        </template>
          <a-tooltip :title="t('funds_printer.add_to_portfolio_tooltip')">
            <label
              class="label-button"
              @click="addToPortfolio()"
              v-if="!authStore.restrictions.includes(Restrictions['AddToPortfolio'])"
            >
              {{ t("funds_printer.add_to_portfolio") }}
            </label>
          </a-tooltip>
          <a-tooltip :title="t('funds_printer.basket_report')">
            <label
              class="label-button"
              @click="printPdf()"
              v-if="!authStore.restrictions.includes(Restrictions['PdfGeneration'])"
              >
              {{ t("funds_printer.basket_report") }}
              <a-spin style="padding-left: 2px; padding-bottom: 3px;" :indicator="indicator" v-if="basketStore.loadingReport || printing"></a-spin>
            </label>
          </a-tooltip>
      </a-space>
      <a-space class="align-items-center">
        <a-tooltip :title="t('generic.titles.select_columns')">
          <label
            class="label-button"
            @click="showColumnsModal('basket')"
            v-if="!authStore.restrictions.includes(Restrictions['CustomizeColumns'])"
          >
          {{ t("generic.titles.select_columns") }}
          </label>
        </a-tooltip>
        <div class="paginationSelectorRow pb-1">
          <label>
            {{ basketStore?.fullData?.length ?? 0 }} {{ t('generic.titles.table_length') }}
          </label>
          <select v-model="basketStore.selectedPageSize" class="native_page_select">
            <option
              v-for="pageSize in paginationPageSizeOptions"
              v-bind:key="pageSize"
              :value="pageSize"
            >
              {{ pageSize }}
            </option>
          </select>
        </div>    
      </a-space>
    </a-space>

    <div class="mobile-table-length">
      <label>
        {{ portfolioStore?.getPositionsTable?.length ?? 0 }} {{ t('generic.titles.table_length') }}
      </label>
    </div>

    <div id="basket-table-part" class="portfolio-mobile-data">
      <full-table
        ref="tableRef"
        :columnDefs="columnDefs"
        @selection-changed="selectionChanged"
        @column-moved="onColumnMoved"
        @column-removed="onColumnReMoved"
        :selectAll="selectAll"
        :rowData="basketStore.fullData" 
      >
      </full-table>
      <family-printer
        v-if="showFamily"
        :selectedFamily="computedSelectedFamily"
        @closeFamily="closeFamily"
        @replaceWithSelected="replaceWithSelected"
        @addSelected="addSelected"
      />
      <pdf-printer ref="pdfprinter" @has-downloaded="hasDownloaded">
      </pdf-printer>
      <div id="basket-graph-pdf" class="print-graph-container">
        <basket-graph :graph="printGraph" :hide-buttons="true"></basket-graph>
      </div>
    </div>
  </div>

  <a-modal
    v-model:visible="visibleColumnModal"
    :title="t('generic.titles.select_columns')"
    :type="'primary'"
    :closable="false"
  >
    <template #footer>
      <a-button key="ok" @click="hideColumnsModal">Ok</a-button>
    </template>
    <a-select
      mode="multiple"
      v-model:value="basketStore.activeColumns"
      :placeholder="t('generic.titles.select_columns')"
      class="native-select"
      size="small"
      :filterOption="customFilter"
      @change="handleChange"
    >
      <a-select-option
        v-for="col in basketStore.allBasketCols"
        :key="$t(`positions_fields.${col.key}`, col.key)"
        :value="col.key"
        :title="$t(`positions_fields.${col.key}`, col.key)"
      >
        {{ $t(`positions_fields.${col.key}`, col.key) }}
      </a-select-option>
    </a-select>
    <div v-if="availableSelect" class="text-right">{{ t('generic.titles.available_text') }}</div>
    <div v-else class="text-right">{{ t('generic.titles.no_available_text') }}</div>
  </a-modal>
</template>

<script setup>
import { useBasketStore } from "@/stores/BasketStore";
import { ref, defineProps, computed, onMounted, watch } from "vue";
import FullTable from "@/components/wiseral/FullTable";
import FamilyPrinter from "@/components/wiseral/familyPrinter";
import ActionIcons from "../actionIcons.vue";
import pdfPrinter from "@/components/pdfPrinter";
import { usePortfolioStore } from "@/stores/PortfolioOverviewStore";
import { useFundsStore } from "@/stores/FundsStore";
import { useAuthStore } from "@/stores/AuthStore";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import BasketGraph from "@/components/wiseral/BasketGraph.vue";
import Cookies from "js-cookie";
import Restrictions from "../../../config/restriction/restrict_functions";
import { LoadingOutlined } from '@ant-design/icons-vue';
import { h } from 'vue';
import { useRouter } from "vue-router";
const indicator = h(LoadingOutlined, {
  style: {
    padding: '0px 1px 4px 1px',
    fontSize: '12px',
  },
  spin: true,
});

let paginationPageSizeOptions = [20, 50, 100, 500];

const router = useRouter();

const portfolioStore = usePortfolioStore();
const basketStore = useBasketStore();
const authStore = useAuthStore();
const { t, n, d } = useI18n();
const showFamily = ref(false);
const fundsStore = useFundsStore();
const selectedFamily = ref([]);
const loadingGraph = ref(false);
const visibleColumnModal = ref(false);
const tableRef = ref(null);
const pdfprinter = ref(null);
const printing = ref(false);
const printDatasets = ref([]);
const printGraph = ref([]);
const availableSelect = ref(false);
const selectAll = ref(false);
const enableAddClass = ref(false);

const props = defineProps({
  assetType: {
    type: String,
    default: function () {
      return "";
    },
  },
  isGraph: {
    type: Boolean,
    default: function () {
      return false;
    }
  }
});

const plot = ref(false);
const enableActions = ref(false);
const computedSelectedFamily = computed(() => {
  return selectedFamily.value;
});
const showDelete = computed(() => {
  return true;
});
const customFilter = (input, option) => {
  return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
var moment = require('moment');
const columnDefs = computed(() => {
  const columns = basketStore.getActiveColumns;
  const columnDefs = [];
  columnDefs.push({
    cellStyle: { "border": "none", "padding-left": "4px", "padding-right": "4px" },
    pinned: "right",
    filter: false,
    sortable: false,
    field: "isin_tkr_id",
    headerName: "",
    minWidth: 80,
    maxWidth: 80,
    cellRenderer: ActionIcons,
    cellRendererParams: {
      addFamilyAction: openShowFamily.bind(this),
      toggleBenchmarkAction: addOrDeleteBenchmark.bind(this),
    },
  });
  const rowData = basketStore.fullData;
  columns.forEach((column) => {
    let tooltip = t(`header_tooltips.${column.key}`, column.key);
    if (tooltip === column.key) {
      tooltip = t(`positions_fields.${column.key}`, column.key);
    }
    let isColumnEmpty = true;
    for (let i = 0; i < rowData?.length; i ++) {
      const cell = rowData[i][column.key];
      if (cell !== '' && cell !== undefined && cell !== null) {
        isColumnEmpty = false;
        break ;
      }
    }
    const newColumnDef = {
      field: column.key,
      headerName: t(`positions_fields.${column.key}`, column.key),
      headerTooltip: tooltip,
      minWidth: 120,
      filter: true,
      hide: isColumnEmpty && rowData?.length > 0,
      cellDataType: ["decimal", "percent"].includes(column.format.format)
        ? "number"
        : ["bool", "boolean"].includes(column.format.format)
          ? "bool" :
            "text",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        if (["decimal", "percent"].includes(column.format.format)) {
          const decimalQty = column.format.decimalQty || 0;
          return n(params.value, column.format.format, {
            minimumFractionDigits: decimalQty,
          });
        } else if (column.format.format === "boolean") {
          return params.value ? 
            t("portfolio_overview.columns.true", true) :
            t("portfolio_overview.columns.false", false);  
          } else {
          
          const isValid = moment(params.value, moment.ISO_8601, true).isValid();
          
          //if (!isNaN(date.getTime())) {
          if (isValid){
            const date = new Date(params.value);
            return d(date, 'short');
          }
          if (column.key === 'fund_type') {
            return t(`search_funds.filter_criteria_category.assets_type.${params.value}`, params.value);
          }
        }
        return params.value;
      },
    };
    if (column.format.format === "percent") {
      newColumnDef["filterParams"] = {
        numberParser: (value) => {
          return !value ? null : parseFloat(value) / 100;
        },
      }
    }
    if (authStore.config["columns_as_index"]?.includes(column.key) && window.innerWidth >= 768) {
      newColumnDef["pinned"] = 'left';
    }
    if (column.key === "name") {
      newColumnDef["tooltipField"] = "name";
    }
    columnDefs.push(newColumnDef);
  });
  return columnDefs;
});
const showColumnsModal = function () {
  visibleColumnModal.value = true;
};
const hideColumnsModal = function () {
  visibleColumnModal.value = false;
};
const generatePDF = async function (selectedData) {
  await Promise.all([
    basketStore.loadReports(selectedData.map((e) => e.isin_tkr)),
    basketStore.generateGraph(selectedData.map((e) => e.isin_tkr_id)),
  ]).then(([reports, graph]) => {
    printDatasets.value = reports;
    printGraph.value = graph;
    setTimeout(() => {
      pdfprinter.value.printReport(reports, "basket", columnDefs.value, selectedData, "asset");
    }, 5000);
  });
};
const printPdf = async function () {
  try {
    printing.value = true;
    printDatasets.value = [];
    const selectedData = tableRef.value?.getSelectedRows().length === 0 ? basketStore.fullData : tableRef.value?.getSelectedRows();
    const fundAssets = selectedData.filter(item => item?.type === 'Fund');
    if (fundAssets?.length === 0) {
      printing.value = false;
      message.warning(t("message.ask_funds"));
    } else if (fundAssets?.length < selectedData?.length) {
      message.warning(t("message.generate_pdf_with_only_funds"));
      await generatePDF(fundAssets);
    } else {
      await generatePDF(fundAssets);
    }
  } catch (err) {
    console.log(err);
    printing.value = false;
  }
};
const hasDownloaded = (result) => {
  if (result) {
    printing.value = false;
  } else {
    message.warning(t("message.failed_pdf"));
    printing.value = false;
  }
};
const addSelected = async (payload) => {
  showFamily.value = false;
  const result = await fundsStore.searchFundsByIsinTkr(payload.selectedFunds.map(item => item.isin_tkr_id));
  basketStore.addAsset(result);
  enableActions.value = false;
};
const replaceWithSelected = async (payload) => {
  showFamily.value = false;
  removeFromBasket(payload.selectedFamily);
  const result = await fundsStore.searchFundsByIsinTkr(payload.selectedFunds.map(item => item.isin_tkr_id));
  basketStore.addAsset(result);
  setTimeout(() => {
    enableActions.value = false;
  }, 3000);
};
const closeFamily = function () {
  showFamily.value = false;
};
const selectionChanged = function (e) {
  enableActions.value = e.api.getSelectedRows().length >= 0;
  enableAddClass.value = e.api.getSelectedRows().length === 1;
};
const onColumnMoved = function (e) {
  basketStore.activeColumns = e.columnApi.getColumnState().map(item => item.colId);
};
const onColumnReMoved = function (e) {
  basketStore.deleteActiveColumn(e.column?.colId);
};
const removeFromBasket = function (tiker) {
  basketStore.removeAsset(tiker);
};
const clearSelectedBasket = function () {
  basketStore.removeAssets(
    tableRef.value?.getSelectedRows().map((f) => f.isin_tkr_id)
  );
  enableActions.value = false;
};
const clearBasket = async function () {
  await basketStore.deleteAllAssets();
};
const addToPortfolio = async () => {
  authStore.logAction('add_fund_to_portfolio');
  const port_prefix = authStore.config['parameters']['prefix_new_portfolios'];
  let selectedData = tableRef.value?.getSelectedRows().length === 0 ? basketStore.fullData : tableRef.value?.getSelectedRows();
  const isinArray = selectedData.map(item => item.isin_tkr_id);
  const result = basketStore?.fullData?.filter(item => isinArray.includes(item.isin_tkr_id));
  basketStore.removeAssets(isinArray);
  await result.forEach((a) => {
    a.min_weight = 0;
    a.max_weight = authStore.config["max_weight"];
    const newFund = {};
    Object.keys(a).forEach(key => {
      if (!key.startsWith(port_prefix) && key !== 'weight' && 
        key !== 'unrealized_gains' && key !== 'invested' && 
        key !== 'titles' && key !== 'market_value') {
      newFund[key] = a[key];
    }
    });
    portfolioStore.addFundToPortfolio(newFund);
  });

  router.push({
    name: "portfolio-overview-ids",
    params: { ids: portfolioStore.selectedPortfolioIds.join(",") },
  });
};
const graphics = async function () {
  let selectedData = [];
  const tableSelectedRow = tableRef.value?.getSelectedRows() ?? [];
  if (!plot.value) {
    selectedData = tableSelectedRow;
    basketStore.addPlotedValues([]);
  } else {
    basketStore.addPlotedValues(
      tableSelectedRow.map((e) => e.isin_tkr_id)
    );
    selectedData = basketStore.fullData;
  }
  if (selectedData?.length > 20) {
    message.warning(t("message.too_many_assets"));
    return;
  }
  try {
    if (selectedData?.length > 0) {
      loadingGraph.value = true;
      await fundsStore.generateDynamicGraph(
        selectedData.map((e) => e.isin_tkr_id)
      );
    }
  } catch (err) {
    loadingGraph.value = false;
  } finally {
    loadingGraph.value = false;
  }
};
const openShowFamily = function (value) {
  const row = basketStore.fullData.filter((e) => e.isin_tkr_id === value);
  if (row.length && typeof row[0].family === "number") {
    selectedFamily.value = value;
    showFamily.value = true;
    basketStore.getFundsByFamily(row[0].family);
  } else {
    message.warning(t("funds_printer.cannot_family"));
  }
};
const addOrDeleteBenchmark = async function (value) {
  const asset = basketStore.fullData.find((e) => e.isin_tkr_id === value);
  if (asset) {
    await portfolioStore.deleteOrAddBenchmark(asset.isin_tkr_id);
  }
};
const updateCookieCols = (activeCols) => {
  Cookies.set("basket-active-columns", JSON.stringify(activeCols.map(col => col.key)));
  availableSelect.value = basketStore.getActiveColumns.length < basketStore.allBasketCols.length;
};
watch(
  () => basketStore.getActiveColumns,
  (newActiveColumns) => {
    updateCookieCols(newActiveColumns);
  }
);
watch(
  () => props.isGraph,
  (e) => {
    if (e) {
      setTimeout(() => {
        graphics();
      });
    }
  }
);
onMounted(() => {
  basketStore.activeColumns = [];
  basketStore.columns = [];
  basketStore.populateColumns();
  const cookieData = JSON.parse(Cookies.get("basket-active-columns") || "[]");
  if (cookieData.length > 0) {
    basketStore.activeColumns = cookieData;
  }
  updateCookieCols(basketStore.getActiveColumns);

  // Draw graph initially
  setTimeout(() => {
    graphics();
  });
});
</script>

<style scoped lang="scss">
@import "~@/static/css/colors.scss";

// :deep(.ag-theme-material.full-table-pagination) {
//   max-height: 450px;
// }
.native_page_select {
  * {
    color: $text-light-color;
  }
}
.paginationSelectorRow {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 10px 0;
  font-size: 14px;
  & label {
    color: $text-light-color;
  }
  & select {
    color: $text-light-color;
    outline: none;
    border: 1px solid $text-light-color;
    border-radius: 12px;
    padding: 1px;
    background: transparent;
  }
}

.label-button {
  font-size: 14px;
  text-decoration: underline;
  margin: 4px 36px 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: $text-light-color;
  }
}

:deep(.ant-checkbox-wrapper) {
  span {
    color: $text-light-color !important;
  }  
}
:deep(.ag-root-wrapper) {
  height: 100%;
}

:deep(.ag-header-cell-text) {
  color: #49c5b1;
  font-size: 14px;
  font-weight: 500;
}
.optimize-button-placement {
  // margin-right: -40px;
  margin-top: 1px;

  @media only screen and (max-width: 1149px) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
  }
}

.table-btn-group {
  padding-bottom: 8px;
}

.mobile-table-length {
  display: none;
}

@media only screen and (max-width: 640px) {
  .table-btn-group {
    display: none;
  }
  .mobile-table-length {
    display: flex;
    justify-content: flex-end;
    padding: 8px;
    font-size: 14px;
    color: #02B388;
    font-weight: 500; 
  }
}

.portfolio-mobile-data {
  @media only screen and (max-width: 1149px) {
    overflow-x: auto;
  }
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.table-item {
  display: flex;
  justify-content: center;
  width: 100%;
}

:deep(.native-select) {
  border: 1px #000 solid;
  padding: 0 !important;
  margin: 0 !important;
  .ant-select-selector {
    border: none !important;
    height: 100% !important;
    padding: 0 !important;
    padding-left: 10px !important;
  }

  &.ant-select-open {
    .ant-select-selector {
      border: none !important;
      height: 100% !important;
    }
  }
}

:deep(.ant-input-number) {
  width: 70px;
}

:deep(.ant-input-number-input) {
  height: 100% !important;
  padding-top: 7px;
}
:deep(.ant-select-selection-search) {
  width: 100px !important;
  #rc_select_0 {
    opacity: 1 !important;
  }
}
.column-btn {
  background-color: #adacaf !important;
}
.column-btn-span {
  color: #adacaf !important;
  &:hover {
    color: #848287 !important;
  }
}
</style>
<style>
.print-graph-container {
  width: 100%;
  position: fixed;
  bottom: 0;
  transform: translateY(150%);
  .highcharts-navigator, .highcharts-scrollbar, .highcharts-range-selector-buttons {
    display: none !important;
  }
}
.icon-not-allowed {
  cursor: not-allowed;
}
</style>
